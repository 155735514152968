import { z } from 'zod';
import { destr } from 'destr';

export const jsonParseBaseSchema = z.string().transform((str, ctx) => {
  try {
    // destr avoid prototype pollution and should be used for all external json.
    // @see https://github.com/unjs/destr
    return destr(str);
  } catch (_e) {
    // XXX: handle error?
    ctx.addIssue({ code: 'custom', message: 'Invalid JSON' });

    return z.NEVER;
  }
});
