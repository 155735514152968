<template>
  <iframe
    ref="iframeRef"
    :src="formUrl"
    frameborder="0"
    hspace="0"
    allowtransparency="true"
    style="width: 100%; overflow: hidden; margin: 0"
  />
</template>

<script setup lang="ts">
import { z } from 'zod';
import { useIframeResizer } from '~/composables/useIframeResizer';
import { jsonParseBaseSchema } from '~/utils/validation/jsonParseBaseSchema';

interface Props {
  formUrl: string;
}

const props = defineProps<Props>();

const emits = defineEmits<{
  success: [];
}>();

const logger = useLogger();
const iframeRef = ref<HTMLIFrameElement>();

useIframeResizer(iframeRef);

const successMessageModel = jsonParseBaseSchema.pipe(
  z.object({
    isDownload: z.boolean()
  })
);

function handlePostMessage(event: MessageEvent) {
  if (!props.formUrl) {
    return;
  }

  let origin;

  try {
    origin = new URL(props.formUrl).origin;
  } catch (e) {
    logger.error('failed to retrieve origin from downloadFormUrl', e);

    return;
  }

  if (
    event.origin !== origin ||
    (typeof event.data === 'string' && event.data.includes('iFrameSizer'))
  ) {
    return;
  }

  const data = successMessageModel.safeParse(event.data);

  if (data.success && data.data.isDownload) {
    emits('success');
  } else if (!data.success) {
    logger.error(
      'failed to parse post message from pardot form',
      data.error.issues
    );
  }
}

onMounted(() => {
  window.addEventListener('message', handlePostMessage, false);
});

onUnmounted(() => {
  window.removeEventListener('message', handlePostMessage);
});
</script>
